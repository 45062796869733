import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProductLayout from "../../components/layouts/product_layout"
import {
    ProductButtons,
    ProductSubHeader,
    ProductParagraph,
    ProductScreenShot,
    ProductHeader,
    ProductUseCase,
    ProductTestimonials,
} from "../../components/shared"
import ProductDemoForm from "../../components/products/product_demo_form"
import YouTubeVideo from "../../components/you_tube_video"

export default function EmailGovernor({ location }) {
    const data = useStaticQuery(graphql`
        query {
            productsYaml(name: { eq: "email-governor" }) {
                title
                description
                app_cloud_link
                copy {
                    tagline
                    header1
                    header2
                    paragraph1
                    paragraph2
                    paragraph3
                    paragraph4
                    demo_message
                    use_cases {
                        title
                        problem
                        solution
                    }
                    testimonials {
                        quote
                        client
                    }
                }
                rating
                ratingCount
            }
            productLogo: file(
                relativePath: { eq: "images/products/email-governor/logo.png" }
            ) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            screenShots: allFile(
                filter: {
                    relativeDirectory: {
                        eq: "images/products/email-governor/screenshots"
                    }
                }
                sort: { fields: [name], order: ASC }
            ) {
                nodes {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `)

    const {
        productsYaml: {
            title,
            description,
            app_cloud_link,
            copy: {
                tagline,
                header1,
                header2,
                paragraph1,
                paragraph2,
                paragraph3,
                paragraph4,
                demo_message,
                use_cases,
                testimonials,
            },
            rating,
            ratingCount,
        },
        productLogo: {
            childImageSharp: { fixed: logoFixed },
        },
        screenShots: { nodes: screenShots },
    } = data

    return (
        <ProductLayout
            title={title}
            description={description}
            location={location}
            tagline={tagline}
            logo={logoFixed}
            rating={rating}
            ratingCount={ratingCount}
        >
            <div className="mt-8 text-xl font-semibold text-center">
                <ProductParagraph content={paragraph1} />
            </div>

            <YouTubeVideo videoId="WlTmsCzZpKU" title="Email Governor Video" />

            <ProductButtons appCloudLink={app_cloud_link} />

            <div className="mt-12">
                <div className="text-center">
                    <ProductHeader content={header2} />
                </div>
                <ProductSubHeader content={header1} />
                <ProductParagraph content={paragraph2} />
                <ProductParagraph content={paragraph3} />
                <ProductParagraph content={paragraph4} />
            </div>

            <ProductScreenShot fluid={screenShots[3].childImageSharp.fluid} />

            <ProductUseCase
                useCase={use_cases[0]}
                screenShotFluid={screenShots[1].childImageSharp.fluid}
            />

            <ProductTestimonials testimonials={testimonials} />

            <ProductDemoForm formMessage={demo_message} />
        </ProductLayout>
    )
}
